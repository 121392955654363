// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-md": () => import("./../src/pages/404.md" /* webpackChunkName: "component---src-pages-404-md" */),
  "component---src-pages-eine-aktive-gemeinde-andachten-md": () => import("./../src/pages/eine-aktive-gemeinde/andachten.md" /* webpackChunkName: "component---src-pages-eine-aktive-gemeinde-andachten-md" */),
  "component---src-pages-eine-aktive-gemeinde-index-md": () => import("./../src/pages/eine-aktive-gemeinde/index.md" /* webpackChunkName: "component---src-pages-eine-aktive-gemeinde-index-md" */),
  "component---src-pages-eine-aktive-gemeinde-juniorjugendprogramm-md": () => import("./../src/pages/eine-aktive-gemeinde/juniorjugendprogramm.md" /* webpackChunkName: "component---src-pages-eine-aktive-gemeinde-juniorjugendprogramm-md" */),
  "component---src-pages-eine-aktive-gemeinde-kinderklassen-md": () => import("./../src/pages/eine-aktive-gemeinde/kinderklassen.md" /* webpackChunkName: "component---src-pages-eine-aktive-gemeinde-kinderklassen-md" */),
  "component---src-pages-eine-aktive-gemeinde-studienkreise-md": () => import("./../src/pages/eine-aktive-gemeinde/studienkreise.md" /* webpackChunkName: "component---src-pages-eine-aktive-gemeinde-studienkreise-md" */),
  "component---src-pages-impressum-md": () => import("./../src/pages/impressum.md" /* webpackChunkName: "component---src-pages-impressum-md" */),
  "component---src-pages-index-md": () => import("./../src/pages/index.md" /* webpackChunkName: "component---src-pages-index-md" */),
  "component---src-pages-kontakt-md": () => import("./../src/pages/kontakt.md" /* webpackChunkName: "component---src-pages-kontakt-md" */),
  "component---src-pages-datenschutzerklaerung-md": () => import("./../src/pages/datenschutzerklaerung.md" /* webpackChunkName: "component---src-pages-datenschutzerklaerung-md" */)
}

