import React, { ReactElement, ReactNode, useState } from "react"

interface LabelProps {
  htmlFor: string
  children: ReactNode
}

function encode(data: any) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

function Label({ htmlFor, children }: LabelProps): ReactElement {
  return (
    <div className="md:w-1/5">
      <label
        className="block pr-4 mb-1 font-bold text-blue-900 md:text-left md:mb-0"
        htmlFor={htmlFor}
      >
        {children}
      </label>
    </div>
  )
}

const fieldStyles =
  "w-full px-4 py-2 leading-tight bg-gray-200 border-2 border-gray-200 rounded appearance-none focus:outline-none focus:bg-white focus:border-brand"

interface InputContainerProps {
  children: ReactNode
}

function InputContainer({ children }: InputContainerProps): ReactElement {
  return <div className="md:w-2/3">{children}</div>
}

enum InputTypes {
  Text = "text",
  Email = "email",
}

interface InputProps {
  id: string
  name: string
  type: InputTypes
  value: string
  onChange: (e: any) => void
  required?: boolean
}

function Input({
  id,
  name,
  type,
  value,
  required,
  onChange,
}: InputProps): ReactElement {
  return (
    <InputContainer>
      <input
        className={fieldStyles}
        id={id}
        name={name}
        type={type}
        value={value}
        required={required && true}
        onChange={onChange}
      />
    </InputContainer>
  )
}

interface InputGroupProps {
  children: ReactNode
}

function InputGroup({ children }: InputGroupProps): ReactElement {
  return <div className="mb-6 md:flex md:items-center">{children}</div>
}

function ContactForm(): ReactElement {
  const initState = {
    name: "",
    email: "",
    nachricht: "",
  }
  const [state, setState] = useState({ ...initState })

  function handleSubmit(e: any) {
    e.preventDefault()
    const form = e.target
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        subject: "Kontakt durch Gemeinde Webseite",
        ...state,
      }),
    })
      .then(() => {
        alert("Vielen Dank für Ihre Nachricht. Wir melden uns zeitnah.")
        setState({ ...initState })
      })
      .catch((error) => alert(error))
  }

  function handleChange(e: any) {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  return (
    <form
      name="contact"
      method="post"
      className="my-8"
      onSubmit={(e) => handleSubmit(e)}
      data-netlify="true"
      data-netlify-honeypot="bot-field"
    >
      <input type="hidden" name="bot-field" />
      <input type="hidden" name="form-name" value="contact" />
      <InputGroup>
        <Label htmlFor="name">Name *</Label>
        <Input
          type={InputTypes.Text}
          id="name"
          name="name"
          value={state.name}
          required
          onChange={(e) => handleChange(e)}
        />
      </InputGroup>
      <InputGroup>
        <Label htmlFor="email">E-Mail *</Label>
        <Input
          type={InputTypes.Email}
          id="email"
          name="email"
          value={state.email}
          required
          onChange={(e) => handleChange(e)}
        />
      </InputGroup>
      <InputGroup>
        <Label htmlFor="nachricht">Nachricht</Label>
        <InputContainer>
          <textarea
            className={fieldStyles}
            value={state.nachricht}
            name="nachricht"
            id="nachricht"
            onChange={(e) => handleChange(e)}
          />
        </InputContainer>
      </InputGroup>
      <div className="md:flex md:items-center">
        <div className="md:w-1/5" />
        <InputContainer>
          <button
            className="px-4 py-2 font-bold text-white rounded cursor-pointer bg-brand hover:bg-blue-400 focus:shadow-outline focus:outline-none"
            type="submit"
          >
            Senden
          </button>
        </InputContainer>
      </div>
    </form>
  )
}

export default ContactForm
