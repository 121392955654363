import React, { ReactElement } from "react"
import { Link } from "gatsby"

interface Props {
  label: string
  to: string
}

function PageLink({ label, to }: Props): ReactElement {
  return (
    <Link
      className="block my-4 mr-0 text-lg font-medium leading-tight tracking-widest no-underline uppercase xs:mr-4 md:mr-8 last:mr-0"
      to={to}
    >
      {label}
    </Link>
  )
}

export default PageLink
